import React from "react"
import { StyledPagination } from "./style"
import { PaginationProps } from "antd"

const Pagination = (props: PaginationProps) => {
  function itemRender(current: any, type: any, originalElement: any) {
    if (type === "prev") {
      return null
    }
    if (type === "next") {
      return null
    }
    return originalElement
  }
  return <StyledPagination {...props} itemRender={itemRender} />
}

export default Pagination
