import React from "react"
import { styled } from "themes"
import { Link } from "gatsby"

export const Section = styled.section`
  width: 100%;
  height: 100%;
`

export const Container = styled((props: any) => <Link {...props} />)`
  position: relative;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
`

export const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &::before {
    background: rgb(0, 0, 0, 0.2);
    border-radius: 8px;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }
`

export const Title = styled.h3`
  position: absolute;
  left: 18px;
  bottom: 18px;
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 0;
  max-width: 182px;
  font-size: 28px;
  line-height: 24px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`

export const Category = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 8px 16px;
  font-size: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  color: #868686;
`
