import React, { useState } from "react"
import {
  Container,
  Description,
  PublishingDate,
  ReadMore,
  Section,
  Thumbnail,
  Title,
  CardBody,
  Action,
} from "./style"

import playButton from "../../../../images/play-button.svg"

const Article = ({
  thumbnail,
  publishingDate,
  title,
  description,
  slug,
  hasVideo,
}: IArticle) => {
  const [isHover, setHover] = useState(false)
  return (
    <Section
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Container to={slug} hover={isHover}>
        <Thumbnail>
          {hasVideo && (
            <img src={playButton} alt="Play button" className="play-button" />
          )}
          <img src={thumbnail} alt={title} className="thumbnail" />
        </Thumbnail>
        <CardBody>
          <PublishingDate>{publishingDate}</PublishingDate>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Action>
            <ReadMore>Read more</ReadMore>
          </Action>
        </CardBody>
      </Container>
    </Section>
  )
}

export default Article
