import { styled } from "themes"
import React from "react"
import { ReactMarkdownProps } from "react-markdown"
import { Markdown, Headline as StyledHeadline } from "components"

export const Section = styled.section``

export const Container = styled.div``

export const Headline = styled((props: any) => <StyledHeadline {...props} />)``

export const Description = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  padding: 12px 0;
  margin-bottom: 0;
  > p {
    font-size: 12px;
  }
`

export const Thumbnail = styled.div`
  margin: 0 -18px;
  > img {
    width: 100%;
  }
`

export const Content = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  padding: 12px 0;
  padding-top: 28px;
  > p {
    font-size: 12px;
  }
`
