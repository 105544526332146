import React from "react"
import { styled } from "themes"
import { Headline as StyledHeadline } from "components"
import { Markdown } from "components"
import { ReactMarkdownProps } from "react-markdown"
import { Popover, Button } from "antd"
import { DownOutlined } from "@ant-design/icons"

export const Section = styled.section``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)``

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const Description = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  padding: 12px 0;
  margin-bottom: 0;
  > p {
    font-size: 12px;
  }
`

export const Filter = styled.div``

export const FilterIcon = styled((props: any) => <DownOutlined {...props} />)`
  &&.anticon {
    margin-left: 24px;
  }
`

export const StyledButton = styled((props: any) => <Button {...props} />)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledPopover = styled((props: any) => (
  <Popover placement="bottom" {...props} />
))``

export const List = styled.div`
  display: flex;
  margin: 0 -6px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
`

export const ListItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
    flex: 1;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 32px;
`

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const FilterList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
`

export const FilterListItem = styled.li`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 0;
  cursor: pointer;
`
