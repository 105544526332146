import React from "react"
import {
  Section,
  Container,
  Headline,
  Thumbnail,
  Detail,
  Content,
  Description,
  Info,
  InfoItem,
  Gallery,
  GalleryItem,
  ShareWrapper,
  GalleryList,
  Navigation,
  NavigationItem,
} from "./style"

import { SocialShare } from "components"

const CaseStudy = ({
  title,
  description,
  thumbnail,
  client,
  date,
  gallery,
}: ICaseStudy) => {
  return (
    <Section>
      <Container>
        <Thumbnail>
          <img src={thumbnail} alt={title} />
        </Thumbnail>
        <Content>
          <Detail>
            <Headline>{title}</Headline>
            <Description>{description}</Description>
          </Detail>
          <Info>
            <InfoItem>
              <label>Client:</label>
              <span>{client}</span>
            </InfoItem>
            <InfoItem>
              <label>Date:</label>
              <span>{date}</span>
            </InfoItem>
          </Info>
        </Content>
        <Gallery>
          <GalleryList>
            {gallery.map((img, k) => (
              <GalleryItem key={k}>
                <img src={img} />
              </GalleryItem>
            ))}
          </GalleryList>
        </Gallery>
        <Navigation>
          <NavigationItem className="prev">
            <label>Previous Project</label>
            <span>
              <div className="arrow" />
              Replace
            </span>
          </NavigationItem>
          <NavigationItem className="next">
            <label>Next Project</label>
            <span>
              Replace
              <div className="arrow" />
            </span>
          </NavigationItem>
        </Navigation>
        <ShareWrapper>
          <SocialShare />
        </ShareWrapper>
      </Container>
    </Section>
  )
}

export default CaseStudy
