import React, { useEffect, useState } from "react"
import {
  Section,
  Container,
  Headline,
  Description,
  List,
  Column,
  ListItem,
  PaginationWrapper,
  ShareWrapper,
  Detail,
  Filter,
  StyledButton,
  StyledPopover,
  FilterList,
  FilterListItem,
  FilterIcon,
} from "./style"
import { Cards, Pagination, SocialShare } from "components"

const filterContent = (
  <FilterList>
    <FilterListItem>Option 1</FilterListItem>
    <FilterListItem>Option 2</FilterListItem>
  </FilterList>
)

const CaseStudiesList = ({
  headline,
  description,
  caseStudies,
}: ICaseStudiesList) => {
  const [separatedData, setData] = useState({})
  const [numberOfColumns] = useState(3)

  useEffect(() => {
    const dataObj = {}
    for (let i = 0; i < numberOfColumns; i++) {
      dataObj[i] = []
    }
    caseStudies.forEach((v, i) => {
      dataObj[i % numberOfColumns].push(v)
    })
    setData(dataObj)
  }, [])

  return (
    <Section>
      <Container>
        <Headline>{headline}</Headline>
        <Detail>
          <Description>{description}</Description>
          <Filter>
            <StyledPopover content={filterContent}>
              <StyledButton type="primary">
                FILTER BY PAPER <FilterIcon />
              </StyledButton>
            </StyledPopover>
          </Filter>
        </Detail>
        <List>
          {Object.keys(separatedData).map(key => (
            <Column style={{ width: `${100 / numberOfColumns}%` }} key={key}>
              {separatedData[key].map((c: ICaseStudy, k: number) => (
                <ListItem key={k}>
                  <Cards.CaseStudy {...c} />
                </ListItem>
              ))}
            </Column>
          ))}
        </List>
        <PaginationWrapper>
          <Pagination defaultCurrent={1} total={100} showSizeChanger={false} />
        </PaginationWrapper>
        <ShareWrapper>
          <SocialShare />
        </ShareWrapper>
      </Container>
    </Section>
  )
}

export default CaseStudiesList
