import React from "react"
import { styled } from "themes"
import { Headline, Markdown } from "components"
import { ReactMarkdownProps } from "react-markdown"

export const Section = styled.section``

export const StyledHeadline = styled((props: any) => <Headline {...props} />)``

export const Description = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  margin-bottom: 0;
  > p {
    font-size: 12px;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin: 0 -18px;
`

export const ListItem = styled.div`
  width: 100%;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 64px 0;
  padding-bottom: 32px;
`

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
`
