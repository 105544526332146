import { styled } from "themes"

export const Container = styled.section``

export const Headline = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 24px;
`

export const SearchWrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 12px;
  padding-left: 42px;
  height: 86px;
  border-radius: 86px;
`

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const SearchInput = styled.input`
  flex: 1;
  outline: none;
  border: none;
  font-size: 18px;
  padding-right: 24px;
`

export const SearchBtn = styled.button`
  background: ${({ theme }) => theme.color.primary};
  height: 100%;
  padding: 0 58px;
  color: ${({ theme }) => theme.color.white};
  outline: none;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`
