import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.9);
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow  {
        border-top-color: #3AB54A;
        border-left-color: #3AB54A;
    }
    .ant-popover-inner {
        background: #3AB54A;
    }
    .ant-popover-inner-content {
        color: #fff;
        min-width: 154px;
    }
`

export default GlobalStyle
