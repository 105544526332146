import React from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import { ResponsiveLayout } from "components"
import { PaginationProps } from "antd"

const Pagination = (props: PaginationProps) => {
  return (
    <ResponsiveLayout
      mobileView={<Mobile {...props} />}
      desktopView={<Desktop {...props} />}
    />
  )
}

export default Pagination
