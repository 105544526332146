import { styled } from "themes"

export const Section = styled.section`
  background: #191919;
`

export const Container = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 42px 145px 24px;
`

export const Content = styled.div`
  display: flex;
`

export const Company = styled.div`
  flex: 3;
  padding-right: 92px;
`

export const Logo = styled.div`
  margin-bottom: 2em;
`

export const Description = styled.p`
  color: #FFFFFF;
  margin-bottom: 2em;
`

export const Headline = styled.h3`
  color: #FFFFFF;
  font-weight: bold;
  margin-bottom: 24px;
`

export const Contact = styled.div`
  flex: 2;
`

export const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const Info = styled.li`
  display: flex;
  margin-bottom: 10px;
`

export const InfoIcon = styled.div`
  margin-right: 12px;
  font-size: 1.2rem;
`

export const Venues = styled.div`
  flex: 2;
  ${InfoIcon} {
    color: #3AB54A;
  }
`

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  color: #FFFFFF;
  > a {
    color: #FFFFFF;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
  border-top: 1px solid #2e2e2e;
`

export const CopyrightText = styled.div`
  color: #FFFFFF;
`

export const CopyrightList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const CopyrightItem = styled.li`
  display: inline-block;
  > a {
    color: #FFFFFF;
    text-decoration: underline;
  }
  &::after {
    content: "-";
    margin: 0 5px;
    color: #FFFFFF;
  }
  &:last-child {
    &::after {
      content: "";
      margin: 0;
    }
  }
`
