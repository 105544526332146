import React from "react"
import {
  Container,
  PublishingDate,
  Section,
  Thumbnail,
  Title,
  CardBody,
} from "./style"

import playButton from "../../../../images/play-button.svg"

const Article = ({
  thumbnail,
  publishingDate,
  title,
  description,
  slug,
  hasVideo,
}: IArticle) => {
  return (
    <Section>
      <Container to={slug}>
        <Thumbnail>
          {hasVideo && (
            <img src={playButton} alt="Play button" className="play-button" />
          )}
          <img src={thumbnail} alt={title} className="thumbnail" />
        </Thumbnail>
        <CardBody>
          <PublishingDate>{publishingDate}</PublishingDate>
          <Title>{title}</Title>
        </CardBody>
      </Container>
    </Section>
  )
}

export default Article
