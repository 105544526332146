import React from "react"
import { Section, Container, Thumbnail, Title, Category } from "./style"

const CaseStudy = ({ title, thumbnail, category, slug }: ICaseStudy) => {
  return (
    <Section>
      <Container to={slug}>
        <Thumbnail>
          <img src={thumbnail} />
        </Thumbnail>
        <Category>{category}</Category>
        <Title>{title}</Title>
      </Container>
    </Section>
  )
}

export default CaseStudy
