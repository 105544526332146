import React from "react"
import { ConfigProvider } from "antd"
import {
  WindowDimensionsProvider,
  GlobalStyle,
  NavigationBar,
  Footer,
} from "components"
import { ThemeProvider } from "styled-components"
import { theme } from "themes"
import "./../index.css"

import logo from "../../images/bf-logo.png"
import footerLogo from "../../images/logo-footer.svg"

const navigationBarProps: INavigationBar = {
  logo,
  label: {
    phone: "Call us:",
    email: "Email us:",
    follow: "Follow us:",
  },
  phone: "0800 263 727",
  email: "sales@bfpapers.co.nz",
  menu: [
    {
      name: "About us",
      url: "/about",
    },
    {
      name: "Our Products",
      url: "/products",
    },
    {
      name: "News",
      url: "/news",
    },
    {
      name: "Case Studies",
      url: "/case-studies",
    },
    {
      name: "Contact Us",
      url: "/contact",
    },
  ],
  actions: [
    {
      text: "Business Login",
      url: "/login",
    },
  ],
}

const footerProps: IFooter = {
  logo: footerLogo,
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita.",
  label: {
    follow: "Follow us",
    contact: "Contact information",
    venues: "Our Venues",
  },
  phone: "0800 263 727",
  email: "sales@bfpapers.co.nz",
  venues: [
    {
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
    {
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
    {
      address: "1772 Nevskaya Street NW, Suite 21389, Atlanta, GA 902344",
    },
  ],
  copyright: "B&F New Zealand © All Rights Reserved.",
  terms: [
    {
      text: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      text: "Terms of Use",
      url: "/terms",
    },
  ],
}

const Containers = ({ children }: any) => {
  return (
    <ConfigProvider>
      <NavigationBar {...navigationBarProps} />
      {children}
      <Footer {...footerProps} />
    </ConfigProvider>
  )
}

const DefaultLayout = (props: any) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <WindowDimensionsProvider>
        <Containers {...props} />
      </WindowDimensionsProvider>
    </ThemeProvider>
  )
}

export default DefaultLayout
