import React from "react"
import {
  Container,
  Headline,
  SearchBtn,
  SearchInput,
  SearchWrapper,
  SearchContent,
} from "./style"

const Search = () => {
  return (
    <Container>
      <Headline>Find everything you need of B&F New Zealand</Headline>
      <SearchWrapper>
        <SearchContent>
          <SearchInput placeholder={"What are you searching ?"} />
          <SearchBtn>Search</SearchBtn>
        </SearchContent>
      </SearchWrapper>
    </Container>
  )
}

export default Search
