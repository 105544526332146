import { styled } from "themes"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`

export const Headline = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 24px;
`

export const SearchWrapper = styled.div`
  text-align: center;
`

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.white};
  padding: 12px;
  padding-left: 28px;
  height: 50px;
  border-radius: 50px;
  margin-bottom: 14px;
`

export const SearchInput = styled.input`
  flex: 1;
  outline: none;
  border: none;
  font-size: 14px;
  padding-right: 24px;
`

export const SearchBtn = styled.button`
  background: ${({ theme }) => theme.color.primary};
  height: 100%;
  padding: 15px 42px;
  color: ${({ theme }) => theme.color.white};
  outline: none;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: auto;
`
