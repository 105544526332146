import React from "react"
import Desktop from "./Desktop"
import { ReactMarkdownProps } from "react-markdown"
import { ResponsiveLayout } from "components"

const Markdown = (props: ReactMarkdownProps) => {
  return (
    <ResponsiveLayout
      mobileView={<Desktop {...props} />}
      desktopView={<Desktop {...props} />}
    />
  )
}

export default Markdown
