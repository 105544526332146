import { styled } from "themes"
import React from "react"
import { ReactMarkdownProps } from "react-markdown"
import { Markdown, Headline as StyledHeadline } from "components"

export const Section = styled.section``

export const Container = styled.div``

export const Headline = styled((props: any) => <StyledHeadline {...props} />)`
  margin-left: 20px;
  margin-right: 20px;
  max-width: 464px;
`

export const Description = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  padding: 34px;
  margin-bottom: 0;
  > p {
    font-size: 14px;
  }
`

export const Thumbnail = styled.div`
  margin: 0 -18px;
  > img {
    width: 100%;
  }
`

export const Content = styled((props: ReactMarkdownProps) => (
  <Markdown {...props} />
))`
  padding: 34px;
  > p {
    font-size: 14px;
  }
`
