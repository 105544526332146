import React from "react"
import { styled } from "themes"
import { Link } from "gatsby"
import { Drawer } from "components"

export const Container = styled.section`
  /* background-color: rgba(0, 0, 0, 0.5); */
  min-height: 87px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
`

export const Header = styled.header`
  color: ${({ theme }) => theme.color.white};
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 12px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 24px;
  position: relative;
  &::after {
    content: "";
    height: 100%;
    border-left: 1px dashed ${({ theme }) => theme.color.white};
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export const Logo = styled((props: any) => <Link to={"/"} {...props} />)`
  margin-right: auto;
`

export const Contact = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
`

export const ContactItem = styled.li`
  display: flex;
  flex-direction: column;
  > label {
    font-weight: bold;
  }
  > span {
    > a {
      font-size: 10px;
      line-height: 15px;
      color: ${({ theme }) => theme.color.white};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const Navbar = styled((props: any) => (
  <Drawer
    width={312}
    key={"right"}
    placement={"right"}
    closable={true}
    {...props}
  />
))`
  color: ${({ theme }) => theme.color.white};
  && {
    .ant-drawer-body {
      background: rgb(0, 0, 0, 0.9);
    }
    .ant-drawer-close {
      display: none;
    }
    .ant-drawer-mask {
      background: transparent;
    }
  }
`

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  padding-left: 62px;
  padding-top: 124px;
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled.li`
  padding: 0 16px;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 0;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
`

export const MenuLink = styled((props: any) => <Link {...props} />)`
  color: ${({ theme }) => theme.color.white};
  font-weight: 500;
  position: relative;
  &::after {
    content: "";
    width: 0;
    height: 1px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: width 0.2s;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
    &::after {
      width: 16px;
    }
  }
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  margin-top: 12px;
  padding: 10px;
  font-size: 10px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.primary};
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  color: ${({ theme }) => theme.color.white};
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`

export const MenuBarIcon = styled.div`
  display: flex;
`
